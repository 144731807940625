import { i18n } from 'lib/i18n';
import { ReactComponent as UserFamily } from 'public/media/user-side-menu/family.svg';
import { ReactComponent as UserRes } from 'public/media/user-side-menu/reservations.svg';
import { ReactComponent as UserSupport } from 'public/media/user-side-menu/support.svg';
import { ReactComponent as UserIcon } from 'public/media/user-side-menu/user.svg';
import { ReactComponent as PaymentMethods } from 'public/media/user-side-menu/paymentMethods.svg';
import { ReactComponent as PurchaseHistory } from 'public/media/user-side-menu/purchaseHistory.svg';
import { ReactComponent as UserPasses } from 'public/media/user-side-menu/passes.svg';
import { getSupportMailto } from 'lib/paths';

export interface MenuItem {
	href: string;
	text: string;
	icon?: React.ReactNode;
	target?: string;
}

const menuItems: MenuItem[] = [
	{
		href: '/user/profile',
		icon: <UserIcon />,
		text: i18n.user.topMenu.myProfile,
	},
	{
		href: '/user/my-family',
		icon: <UserFamily />,
		text: i18n.user.topMenu.myFamily,
	},
	{
		href: '/user/my-teams',
		icon: <UserFamily />,
		text: i18n.user.topMenu.myTeams,
	},
	{
		href: '/user/reservations',
		icon: <UserRes />,
		text: i18n.user.topMenu.reservations,
	},
	{
		href: '/user/payment-methods',
		icon: <PaymentMethods />,
		text: i18n.user.topMenu.paymentMethods,
	},
	{
		href: '/user/passes',
		icon: <UserPasses />,
		text: i18n.user.topMenu.passes,
	},
	{
		href: '/user/invoices',
		icon: <PurchaseHistory />,
		text: i18n.user.topMenu.purchaseHistory,
	},
	{
		href: getSupportMailto(),
		icon: <UserSupport />,
		text: i18n.user.topMenu.support,
		target: '_blank',
	},
];

const UserMenuItems = ({ render }: { render: (menuItems: MenuItem[]) => JSX.Element[] }) => {
	return <>{render(menuItems)}</>;
};

export default UserMenuItems;
