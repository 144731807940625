// We enforce not to import from `next/link` so all Link
// components will use this component. Thus we need to disable the lint rule.
// eslint-disable-next-line no-restricted-imports
import NextLink, { LinkProps } from 'next/link';

/**
 * @see
 * https://nextjs.org/docs/api-reference/next/link
 */
interface Props extends React.PropsWithChildren<LinkProps> {
	anchorProps?: React.AnchorHTMLAttributes<HTMLAnchorElement>;
}

/**
 * A wrapper around <NextLink> to enforce proper usage.
 */
function Link({ children, anchorProps, ...nextLinkProps }: Props) {
	return (
		<NextLink passHref prefetch={false} {...nextLinkProps}>
			<a  data-testid="Link-BFDA93" {...anchorProps}>{children}</a>
		</NextLink>
	);
}

export default Link;
