import { network } from '../network';
import {CookiesWorker} from "lib/auth/CookiesWorker";

const apiUrl = process.env.BOND_SPORTS_API_URL;

function isLogin() {
	return CookiesWorker.hasBondUserDetails();
}

function logout() {
	if (!process.env.NEXT_PUBLIC_LOGOUT_REDIRECT_URL) {
		console.error("Logout URL is not set");
		return;
	}

	const logoutUrl = new URL(process.env.NEXT_PUBLIC_LOGOUT_REDIRECT_URL);
	logoutUrl.searchParams.set('state', window.location.toString());

	window.location.assign(logoutUrl.toString());
}

async function resetPassword(password: string, passwordRepeat: string, token: string) {
	const data = {
		password: password,
		passwordRepeat: passwordRepeat,
		token: token,
	};

	const response = await network.post(`${apiUrl}auth/password-reset`, data);

	return response;
}

export const authApi = {
	logout,
	isLogin,
	resetPassword,
};
