import { useCallback, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Avatar, Hidden, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { i18n } from 'lib/i18n';

import { useRecoilState } from 'recoil';
import { useColors } from 'hooks/useColors';
import userAvatarFallbackUrl from 'public/media/icons/user-avatar-fallback.svg';

import Button from 'components/shared/Button/Button';
import Link from 'components/shared/Link/Link';

import { authApi } from 'lib/api/authApi';
import UserMenuItems, { MenuItem as MenuItemType } from 'components/User/UserMenuItems';
import { userStore } from 'stores/userStore';
import { IColors } from 'types/colors';

import { mobileOnly } from 'styles/utils';

const userBtnCss = css`
	&.MuiButton-root {
		padding-right: 0;
	}

	.MuiButton-label {
		.MuiAvatar-root {
			height: 30px;
			width: 30px;
			margin-right: 20px;
		}

		span {
			padding-right: 10px;
		}

		svg {
			font-size: 2rem;
			opacity: 0.5;
		}
	}
`;

const userMenuCss = (colors: IColors) => css`
	.MuiPaper-root {
		margin-top: 32px;
	}

	a {
		color: ${colors.tx_text_primary};
		display: flex;
		align-items: center;
		margin-top: 10px;
	}
`;

const iconCSS = css`
	&.MuiListItemIcon-root {
		min-width: 40px;
	}
`;

const logoutCss = (colors: IColors) => css`
	&.MuiMenuItem-root {
		color: ${colors.tx_text_primary};
		margin-top: 10px;
	}
`;

const userNameTextCss = css`
	${mobileOnly} {
		overflow: hidden;
		max-width: 100px;
		white-space: nowrap;
	}
`;

interface UserMenuFormProps {
	showMenu: (values: boolean) => void;
}

const UserMenu = ({ showMenu }: UserMenuFormProps) => {
	const userBtnRef = useRef(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const userSettings = userStore.useInitUserSettings();
	const [loginSettings, setLoginSettings] = useRecoilState(userStore.loginSettings);
	const { colors } = useColors();
	const [, setUserSettings] = useRecoilState(userStore.userSettings);

	const handleLogout = useCallback(() => {
		authApi.logout();
		setUserSettings(null);

		if (loginSettings?.isLogin) {
			setLoginSettings({
				isLogin: false,
				userToken: ' ',
				userId: 0,
			});
			showMenu(false);
		}
		
	}, [loginSettings?.isLogin, setLoginSettings, setUserSettings, showMenu]);

	return (
		<div data-aid="UserMenu" ref={userBtnRef}>
			<Button
				data-aid="button-UserMenu-set"
				css={userBtnCss}
				variant="text"
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={() => setIsMenuOpen(open => !open)}
			>
				{userSettings?.profilePicture?.url ? (
					<Avatar src={userSettings?.profilePicture.url} />
				) : (
					<Avatar src={userAvatarFallbackUrl} />
				)}
				<span css={userNameTextCss}>
					{userSettings?.firstName} {userSettings?.lastName}
				</span>
				<ExpandMoreIcon />
			</Button>

			<Menu
				css={userMenuCss(colors)}
				anchorEl={userBtnRef.current}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				keepMounted
				open={isMenuOpen}
				onClose={() => setIsMenuOpen(false)}
			>
				<Hidden mdDown implementation="js">
					<MenuItem>
						<Link href="/user/profile" aria-label="My Profile">
							{i18n.user.topMenu.myProfile}
						</Link>
					</MenuItem>
				</Hidden>
				<Hidden mdUp implementation="js">
					<UserMenuItems
						render={(menuItems: MenuItemType[]) => {
							return menuItems.map((menuItem: MenuItemType, index: number) => {
								return (
									<MenuItem key={index}>
										<Link href={menuItem.href} aria-label={menuItem.text}>
											<ListItemIcon css={iconCSS}>{menuItem.icon}</ListItemIcon>
											<Typography variant="inherit">{menuItem.text}</Typography>
										</Link>
									</MenuItem>
								);
							});
						}}
					/>
				</Hidden>
				<MenuItem divider />
				<MenuItem css={logoutCss(colors)} onClick={handleLogout}>
					{i18n.user.topMenu.logout}
				</MenuItem>
			</Menu>
		</div>
	);
};

export default UserMenu;
